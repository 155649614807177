<template>
  <Layout>
    <add-campaign-modal @added="newCampaignAdded" />
    <div class="clearfix"></div>
    <div class="card campign">
      <div class="card-body rounded-lg">
        <b-tabs @activate-tab="campignTabChange">
          <b-tab :active="currentTab === 0">
            <template #title>
              {{ campaignData["0"] }}
            </template>
            <div v-if="currentTab === 0">
              <Table
                :status="0"
                :reload="reloadData"
                @reloaded="reloadData = false"
              />
            </div>
          </b-tab>

          <b-tab :active="currentTab === 1">
            <template #title>
              {{ campaignData["1"] }}
            </template>
            <div v-if="currentTab === 1">
              <Table :status="1" />
            </div>
          </b-tab>
          <b-tab :active="currentTab === 2">
            <template #title>
              {{ campaignData["2"] }}
            </template>
            <div v-if="currentTab === 2">
              <Table :status="2" />
            </div>
          </b-tab>

          <b-tab :active="currentTab === 3">
            <template #title>
              {{ campaignData["5"] }}
            </template>
            <div v-if="currentTab === 3">
              <Table :status="-3" />
            </div>
          </b-tab>

          <b-tab :active="currentTab === 4">
            <template #title>
              {{ campaignData["3"] }}
            </template>
            <div v-if="currentTab === 4">
              <Table :status="3" />
            </div>
          </b-tab>
 
          <b-tab :active="currentTab === 5">
            <template #title> {{ campaignData["-1"] }}</template>
            <div v-if="currentTab === 5">
              <Table :status="-1" />
            </div>
          </b-tab>
        </b-tabs>
      </div>
    </div>
  </Layout>
</template>

<script>
import Layout from "@/views/layouts/main";
import { campaignData } from "@/data/campaign";
import AddCampaignModal from "@/components/campaigns/add-campaign-modal";
import Table from "@/components/campaigns/campaigns-table";
export default {
  data() {
    return {
      campaignData: campaignData,
      currentTab: this.$store.getters["campaign/currentTab"],
      reloadData: false,
    };
  },
  components: { Layout, Table, AddCampaignModal },
  methods: {
    campignTabChange(val) {
      this.$store.dispatch("campaign/changeTab", val);
      this.$store.dispatch("campaign/changePage", 1);
      this.currentTab = val;
    },
    newCampaignAdded() {
      this.$store.dispatch("campaign/changeTab", 0);
      this.$store.dispatch("campaign/changePage", 1);
      this.currentTab = 0;
      this.reloadData = true;
    },
  },
};
</script>

<style>
.campign .nav-tabs > li > a,
.nav-pills > li > a {
  color: rgb(85, 83, 83);
}
</style>
